import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Contentbox from "../components/contentbox"
import Button from "../components/button"

const StyledBecomeACoach = styled.div``

const BecomeACoach = () => {
  return (
    <Layout background>
      <Contentbox background>
        <h1>Become a coach</h1>
        <p>
          To become a teamgym.se coach you first need to get approved. Click the
          button below to start the process
        </p>
        <a href="mailto:teamgym@katalysatorav.se">
          <Button>Apply</Button>
        </a>
      </Contentbox>
    </Layout>
  )
}

export default BecomeACoach
