import React from "react"
import styled from "styled-components"
import { colors } from "./siteVars"

const StyledContentbox = styled.div`
  padding: 1.5rem;
  border-radius: 1rem;
  margin-bottom: 2rem;

  ${props =>
    props.inset
      ? `background: #f0f0f0;
            box-shadow: inset 10px 10px 20px #cccccc, 
            inset -10px -10px 20px #ffffff; margin-bottom: 0; margin-top: 2rem;`
      : `background: linear-gradient(135deg, #e3e3e3 0%, #f3f3f3 20%, #ffffff);
  ${colors.boxShadowContentBox}`}
  ${props => (props.background ? "box-shadow: none;" : "")}
`

const Contentbox = props => {
  return (
    <StyledContentbox
      className={props.className}
      inset={props.inset}
      background={props.background}
    >
      {props.children}
    </StyledContentbox>
  )
}

export default Contentbox
